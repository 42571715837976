import React from "react"
import { Link } from "gatsby"
import SEO from "../components/Seo"
import ThemeContext from "../context/ThemeContext"

const Error = () => {
  return (
    <>
      <SEO title="Not Found" />
      <ThemeContext.Consumer>
        {theme => (
          <main className="error-page">
            <div className="error-container">
              <h1>
                {theme.language == "JP"
                  ? "ページが見つかりません。"
                  : "No Page Sorry."}
              </h1>
              <Link
                to="/"
                className="btn"
                style={{ color: "var(--clr-grey-1)" }}
              >
                {theme.language == "JP" ? "ホームへ戻る。" : "Back Home"}
              </Link>
            </div>
          </main>
        )}
      </ThemeContext.Consumer>
    </>
  )
}

export default Error
